<template>
  <div>
    <VCupertino
      v-if="isShowModal"
      id="dialog-create-review"
      :is-show="isShowModal"
      :drawer-options="{
        modal:$viewport.isGreaterOrEquals('md'),
        cssClass:'cupertino-modal'
      }"

      @backdropTap="closeCreateReview"
      @didDismiss="closeCreateReview"
    >
      <div class="dialog-create-review">
        <div class="dialog-create-review__body">
          <div class="dialog-create-review__title h2">Ваш отзыв о товаре</div>
          <div class="dialog-create-review__divider --hide-mobile" />
          <div class="product-card-review">
            <div class="product-card-review__image">
              <nuxt-img
                :src="String(product?.imageId)"
                width="160"
                height="160"
                fit="default"
                :modifiers="{'border': 'default'}"
              />
            </div>
            <div class="product-card-review__content">
              <div class="product-card-review__name h4">
                {{ product?.name }}
              </div>
              <div class="product-card-review__info">
                <div class="--rating">
                  <img src="~/assets/img/svg/common/rating.svg" />
                  {{ reviewRating }}
                </div>
                <div class="--review">
                  {{ reviewCount }}
                </div>
              </div>
            </div>
          </div>
          <div class="dialog-create-review__divider" />
          <div class="form-review">
            <div class="form-review__rating">
              <div class="--label h4">Оцените покупку:</div>
              <div class="--grade">
                <div
                  v-for="(rating) in [1,2,3,4,5]"
                  :key="`rating-${rating}`"
                  :class="{'selected': rating <= formReview.rating}"
                  @click="() => changeFormReview('rating', rating)"
                />
              </div>
            </div>
            <div class="form-review__comment">
              <textarea
                v-model="formReview.message"
                class="textarea"
                placeholder="Поделитесь впечатлениями о товаре *"
              />
              <div
                v-for="(error, index) in $v.message.$errors"
                :key="index"
                class="error-valid"
              >
                {{ error.$message }}
              </div>
            </div>
            <div class="form-review__images">
              <div class="--label h4">Добавьте фотографии</div>
              <div class="--items">
                <div
                  v-for="(image, index) in formReview.images"
                  class="--image"
                >
                  <img :src="_URL.createObjectURL(image)" />
                  <div
                    class="--remove"
                    @click="() => removeImageFromForm(index)"
                  />
                </div>
                <label class="--icon">
                  <img src="~/assets/img/svg/common/image.svg" />
                  <input
                    type="file"
                    accept="image/jpeg,image/png"
                    hidden
                    multiple
                    @change="uploadFormImages"
                  />
                </label>
                <div
                  v-if="formReview.images.length <= 0"
                  class="--message h5"
                >
                  Нажмите на кнопку или перетащите фото в эту область до 10 изображений в формате PNG, JPEG.
                </div>
              </div>
            </div>
            <div class="form-review__images">
              <div class="--label h4">Добавьте видео</div>
              <div class="--items">
                <div
                  v-for="(image, index) in formReview.video"
                  class="--image"
                >
                  <video>
                    <source
                      :src="_URL.createObjectURL(image)"
                      type="video/mp4"
                    />
                  </video>
                  <div
                    class="--remove"
                    @click="() => removeVideoFromForm(index)"
                  />
                </div>
                <label class="--icon">
                  <img src="~/assets/img/svg/common/video.svg" />
                  <input
                    type="file"
                    accept="video/*"
                    hidden
                    multiple
                    @change="uploadFormVideo"
                  />
                </label>
                <div
                  v-if="formReview.video.length <= 0"
                  class="--message h5"
                >
                  Нажмите на кнопку или перетащите фото в эту область до 3 видео длительностью не более 5 минут
                </div>
              </div>
            </div>
            <div class="form-review__input">
              <input
                v-model="formReview.name"
                type="text"
                class="input"
                placeholder="Ваше имя *"
              />
              <div
                v-for="(error, index) in $v.name.$errors"
                :key="index"
                class="error-valid"
              >
                {{ error.$message }}
              </div>
            </div>
            <div class="form-review__input">
              <input
                v-model="formReview.email"
                type="text"
                class="input"
                placeholder="Ваш Email адрес *"
              />
              <div
                v-for="(error, index) in $v.email.$errors"
                :key="index"
                class="error-valid"
              >
                {{ error.$message }}
              </div>
            </div>
            <template v-if="isUserAdmin">
              <div class="form-review__input">
                <input
                  v-model="formReview.source"
                  type="text"
                  class="input"
                  placeholder="Источник * (ЯндексКарты, 2GIS)"
                />
                <div
                  v-for="(error, index) in $v.source.$errors"
                  v-if="$v.source"
                  :key="index"
                  class="error-valid"
                >
                  {{ error.$message }}
                </div>
              </div>
              <div class="form-review__input">
                <input
                  v-model="formReview.source_url"
                  type="text"
                  class="input"
                  placeholder="Источник * (Ссылка)"
                />
                <div
                  v-for="(error, index) in $v.source_url.$errors"
                  v-if="$v.source_url"
                  :key="index"
                  class="error-valid"
                >
                  {{ error.$message }}
                </div>
              </div>
            </template>
            <div class="form-review__button">
              <button
                class="btn btn-primary"
                @click="submitReviewForm"
              >
                Отправить отзыв
              </button>
              <div class="--message h6">
                Перед отправкой ознакомьтесь, пожалуйста,<br/>
                <a href="/policy" target="_blank" class="hover-decoration">с политикой конфиденциальности.</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </VCupertino>
  </div>
</template>

<script setup lang="ts">
import { useCreateReviewModal } from '~/composables/useCreateReviewModal';
import { computed, ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, helpers, required } from '@vuelidate/validators';
import { useCommonStore } from '~/store/common';
import { useNotificationModal } from '~/composables/useNotificationModal';

const $viewport = useViewport();
const VCupertino = defineAsyncComponent(() => import('~/components/widget/VCupertino.vue'));
const isShowModal = ref<boolean>(false);
const {
  product,

  closeCreateReviewModal
} = useCreateReviewModal();

const {
  changeLoading
} = useCommonStore();
const {
  openNotificationModal
} = useNotificationModal();

const {
  $api,
  $toast,

  $caseWords,
  $numberFormat
} = useNuxtApp();
const {
  userManagerInfo
} = useSitisUserManager();
const _URL: any = window.URL;

const isUseClassesModal = computed(() => Boolean($viewport.isGreaterOrEquals('lg')));
const isUserAdmin = computed(() => {
  return Boolean(userManagerInfo.value?.id);
});

const reviewCount = computed(() => {
  const count = (product.value?.reviewsCount || 0);
  const word = $caseWords(count, ['отзыв', 'отзыва', 'отзывов']);
  return [count, word].join(' ');
});
const reviewRating = computed(() => $numberFormat((product.value?.reviewsCount || 0), 1, '.'));

// Логика заполенения формы
const validationForm = computed(() => {
  if (isUserAdmin.value) {
    return {
      name: {
        required: helpers.withMessage('Обязательно к заполнению', required)
      },
      email: {
        email: helpers.withMessage('Неверный формат', email),
        required: helpers.withMessage('Обязательно к заполнению', required)
      },
      message: {
        required: helpers.withMessage('Обязательно к заполнению', required)
      },

      source: {
        required: helpers.withMessage('Обязательно к заполнению', required)
      },
      source_url: {
        required: helpers.withMessage('Обязательно к заполнению', required)
      }
    };
  }
  return {
    name: {
      required: helpers.withMessage('Обязательно к заполнению', required)
    },
    email: {
      email: helpers.withMessage('Неверный формат', email),
      required: helpers.withMessage('Обязательно к заполнению', required)
    },
    message: {
      required: helpers.withMessage('Обязательно к заполнению', required)
    }
  };
});
const formReview: any = ref({
  name: '',
  email: '',
  rating: 0,
  message: '',
  source: '',
  source_url: '',
  video: [],
  images: []
});
const $v = useVuelidate(validationForm, formReview);
const initForm = () => {
  if (!isUserAdmin.value) {
    return;
  }
  formReview.value.email = 'content@mia-sofia.ru';
};
const changeFormReview = (name: string, value: any) => {
  formReview.value[name] = value;
};
const uploadFormImages = (event: any) => {
  const images = [...(event?.target?.files || [])];
  formReview.value.images = [...formReview.value.images, ...images];
  event.target.value = null;
};
const removeImageFromForm = (index: number) => {
  const images = [...formReview.value.images];
  images.splice(index, 1);
  formReview.value.images = images;
};
const uploadFormVideo = (event: any) => {
  const video = [...(event?.target?.files || [])];
  formReview.value.video = [...formReview.value.video, ...video];
  event.target.value = null;
};
const removeVideoFromForm = (index: number) => {
  const video = [...formReview.value.video];
  video.splice(index, 1);
  formReview.value.video = video;
};
const submitReviewForm = async () => {
  const isFormCorrect = await $v.value.$validate();
  if (!isFormCorrect) {
    return false;
  }

  changeLoading(true);

  const formData: any = new FormData();
  formData.append('name', formReview.value.name);
  formData.append('email', formReview.value.email);
  formData.append('text', formReview.value.message);
  formData.append('entities[0][model]', 'Product');
  formData.append('entities[0][model_key]', product.value?.id);

  (formReview.value.video || []).map((file: any) => {
    formData.append('attachments[]', file);
  });
  (formReview.value.images || []).map((file: any) => {
    formData.append('attachments[]', file);
  });
  if (formReview.value.rating > 0) {
    formData.append('rating', formReview.value.rating);
  }

  if (formReview.value.source) {
    formData.append('source', formReview.value.source);
  }
  if (formReview.value.source_url) {
    formData.append('source_url', formReview.value.source_url);
  }

  const res = await $api.agent.post('/reviews', {
    body: formData
  }).then((res) => res?._data).catch((err) => ({ error: err.response }));
  if (res?.error) {
    changeLoading(false);
    const errorMessage = (res?.error?._data || []).map((t: any) => t.message).join('.<br/>');
    $toast.error(errorMessage || 'Ошибка сервера');
    return;
  }
  changeLoading(false);
  closeCreateReview();
  openNotificationModal({
    title: 'Спасибо за отзыв!',
    message: 'Совсем скоро мы проверим его и опубликуем на странице товара'
  });
};

// Закрытие формы
const closeCreateReview = () => {
  closeCreateReviewModal();
};

onMounted(() => {
  initForm();
  isShowModal.value = true;
});
</script>

<style scoped lang="scss">
.dialog-create-review {
}

.dialog-create-review__body {
	max-width: 550px;
	padding: 41px;
}

.dialog-create-review__divider {
	margin: 31px 0;
	width: 100%;
	height: 1px;
	background-color: #F4F3F1;
}

.product-card-review {
	display: flex;
}

.product-card-review__image {
	width: 100%;
	max-width: 100px;
	position: relative;
	border-radius: 16px;
	overflow: hidden;
	background-color: #F4F3F1;
	margin-right: 24px;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: contain;
		mix-blend-mode: multiply;
	}

	&:after {
		content: "";
		float: left;
		padding-top: 100%;
	}
}

.product-card-review__content {
	display: flex;
	flex-direction: column;
	padding: 6px 0;
	box-sizing: border-box;
	flex: 1;
	overflow: hidden;
}

.product-card-review__name {
}

.product-card-review__info {
	display: flex;
	align-items: center;
	margin-top: auto;

	color: #989898;
	font-size: 12px;
	font-weight: 500;
	line-height: 120%;

	.--rating {
		display: flex;
		align-items: center;
	}

	.--rating img {
		width: 12px;
		height: 16px;
		object-fit: contain;
		margin-right: 6px;
	}

	.--review {
		margin-left: 14px;
		text-decoration: underline;
	}
}

.form-review {
}

.form-review__rating {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.--label {
		color: black;
	}

	.--grade {
		display: flex;
		align-items: center;

		& > * {
			margin-left: 10px;
			width: 28px;
			height: 28px;
			cursor: pointer;
			background-image: url("@/assets/img/svg/common/star-empty.svg");

			&:hover {
				transform: scale(1.1);
			}

			&:first-child {
				margin-left: 0;
			}

			@for $i from 1 through 5 {
				&:nth-child(#{$i}) {
					&.selected {
						background-image: url("@/assets/img/svg/common/star.svg");
					}
				}
			}
		}
	}
}

.form-review__comment {
	margin-top: 40px;
}

.form-review__images {
	margin-top: 32px;

	.--label {
		color: black;
		margin-bottom: 16px;
	}

	.--items {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		margin-top: -16px;
		margin-left: -16px;

		& > * {
			margin-top: 16px;
			margin-left: 16px;
		}
	}

	.--icon, .--image {
		width: calc(100% / 4 - 16px);
		max-width: 100px;
		position: relative;
		border-radius: 16px;
		overflow: hidden;

		img, video {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&:after {
			content: "";
			float: left;
			padding-top: 100%;
		}
	}

	.--icon {
		background-color: #F4F3F1;
		cursor: pointer;

		img {
			width: 40px;
			filter: invert(60%) sepia(11%) saturate(9%) hue-rotate(111deg) brightness(99%) contrast(90%);
		}
	}

	.--image img,
	.--image video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.--remove {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 16px;
		height: 16px;
		background-color: white;
		border-radius: 100%;
		background-image: url("@/assets/img/svg/cart/plus.svg");
		background-size: 10px;
		background-position: center;
		background-repeat: no-repeat;
		transform: rotate(45deg);
		cursor: pointer;
	}

	.--message {
		color: #989898;
		flex: 1;
	}
}

.form-review__input {
	margin-top: 32px;

	input {
		height: 60px;
	}
}

.form-review__button {
	margin-top: 32px;

	.btn {
		width: 100%;
		height: 60px;
		text-align: center;
		border-radius: 16px;
		font-weight: 600;
	}

	.--message {
		margin-top: 32px;
		text-align: center;
		color: #989898;
		line-height: 120%;
	}
}

@media (max-width: 1023px) {
	.dialog-create-review__body {
		padding: 25px 20px;
	}
	.dialog-create-review__title {
		font-size: 20px;
	}
	.dialog-create-review__divider {
		margin: 24px 0;

		&.--hide-mobile {
			display: none;
		}
	}

	.product-card-review {
		border: 0;
		border: none;
	}
	.product-card-review__image {
		max-width: 70px;
		border-radius: 12px;
		margin-right: 16px;
	}
	.product-card-review__name {
		white-space: initial;
		max-width: 215px;
		margin-bottom: 13px;

		font-size: 14px;
	}
	.product-card-review__content {
		padding: 0;
		padding-top: 2px;
	}
	.product-card-review__info {
		margin-top: initial;
		line-height: 100%;

		.--rating {
			img {
				width: 18px;
				height: 18px;
				margin-right: 4px;
			}
		}
	}

	.form-review__rating,
	.form-review__images {
		flex-direction: column;
		align-items: flex-start;

		.--label {
			margin-bottom: 14px;
			font-size: 16px;
		}
	}
	.form-review__comment {
		margin-top: 24px;

		.textarea {
			height: 129px;
			padding: 17px 23px;
			border-radius: 16px;
			font-size: 12px;
		}
	}
	.form-review__images {
		margin-top: 17px;

		.--icon {
			width: 70px;
			height: 70px;
		}

		.--message {
			font-size: 12px;
		}
	}
	.form-review__input {
		input {
			height: 48px;
			border-radius: 16px;
			padding: 0 23px;
			font-size: 12px;
		}
	}
	.form-review__button {
		margin-top: 24px;

		.btn {
			height: 48px;
			border-radius: 12px;
		}
	}

	.modal {
		padding: 20px 0;
	}
	.modal__body {
		border-radius: 24px 24px 0 0;
	}
	.modal__close {
		display: none;
	}
}
</style>
